import React, { useState, useEffect } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { useClickOutside } from "react-click-outside-hook";

import {
  GiftFromAChildInitiativeIcon,
  ResearchPartnershipsIcon,
  FoundationPartnershipsIcon,
  DataTypesIcon,
  InformaticsPortalsIcon,
  PediatricBrainTumorAtlasIcon,
  InstitutionIcon,
  BrainTumorTypeIcon,
  PresentationIcon,
  PublicationIcon,
  ProjectIcon,
  PersonIcon,
} from "./Images";

import LogoImage from "../images/CBTN_Logo_Icon.png";

import { Smallcaps } from "./Prose";
import { Image } from "./Image";
import { Slide } from "./Slide";

import { Button } from "./Button";
import { NotificationContainer } from "./Notification";

export const Header = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      wagtail {
        page(slug: "cbtn") {
          ... on Wagtail_HomePage {
            supportEmail
            facebookUrl
            twitterUrl
            instagramUrl
            youtubeUrl
          }
        }
      }
    }
  `);
  const {
    supportEmail,
    facebookUrl,
    twitterUrl,
    instagramUrl,
    youtubeUrl,
  } = data?.wagtail?.page;

  const navInit = {
    explore: false,
    forFamilies: false,
    forResearchers: false,
  };
  const [nav, setNav] = useState(navInit);
  const [open, setOpen] = useState(false);
  const [ref, hasClickedOutside] = useClickOutside();

  useEffect(() => {
    if (hasClickedOutside) {
      setNav(navInit);
    }
  }, [hasClickedOutside]);

  return (
    <>
      <NotificationContainer />
      <header className="z-50 relative bg-white">
        <div className="relative z-10 shadow">
          <div className="max-w-7xl mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-evenly md:space-x-10">
            <div>
              <Link to="/" className="flex">
                <Image
                  className="h-8 w-auto sm:h-10 mr-24"
                  src={LogoImage}
                  alt="CBTN"
                />
              </Link>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <button
                type="button"
                onClick={() => setOpen(true)}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover: hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus: transition duration-150 ease-in-out"
              >
                <svg
                  className="block h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>

            <nav
              ref={ref}
              title="Main Site Navigation"
              className="hidden md:flex space-x-10 items-center"
            >
              <div className="relative">
                <button
                  onClick={() => setNav({ ...navInit, explore: !nav.explore })}
                  type="button"
                  className={`${
                    nav.explore ? "" : ""
                  } group inline-flex items-center space-x-2 text-base leading-6 font-medium hover: focus:outline-none focus: transition ease-in-out duration-150`}
                >
                  <span>About</span>
                  {/*<!-- Item active: "", Item inactive: "text-gray-700" -->*/}
                  <svg
                    className="text-gray-700 h-5 w-5 group-hover: group-focus: transition ease-in-out duration-150"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                <div
                  className={`${
                    nav.explore
                      ? "duration-200 ease-out opacity-100 translate-y-0 visible"
                      : "duration-150 opacity-0 translate-y-3 invisible"
                  } z-20 hidden md:block absolute inset-x-0 transform shadow-lg`}
                >
                  <div className="bg-white">
                    <div className="absolute left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
                      <div className="rounded-lg shadow-lg">
                        <div className="rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <div className="z-20 relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                            <Link
                              to="/about-us"
                              className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="h-6 w-6 flex-shrink-0 text-navy-700"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                              <div className="space-y-1">
                                <p className="text-base leading-6 font-medium text-gray-900">
                                  What is the CBTN
                                </p>
                              </div>
                            </Link>
                            <Link
                              to="/institutions"
                              className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                            >
                              <InstitutionIcon className="h-6 w-6 flex-shrink-0 text-navy-700" />

                              <div className="space-y-1">
                                <p className="text-base leading-6 font-medium text-gray-900">
                                  Member Institutions
                                </p>
                              </div>
                            </Link>
                            <Link
                              to="/research-partners"
                              className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                            >
                              <PersonIcon className="h-6 w-6 flex-shrink-0 text-navy-700" />
                              <div className="space-y-1">
                                <p className="text-base leading-6 font-medium text-gray-900">
                                  Research Collaborators
                                </p>
                              </div>
                            </Link>
                            <Link
                              to="/frequently-asked-questions"
                              className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="h-6 w-6 flex-shrink-0 text-navy-700"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                              <div className="space-y-1">
                                <p className="text-base leading-6 font-medium text-gray-900">
                                  Frequently Asked Questions
                                </p>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative">
                {/*<!-- Item active: "", Item inactive: "" -->*/}
                <button
                  onClick={() =>
                    setNav({ ...navInit, forFamilies: !nav.forFamilies })
                  }
                  type="button"
                  className=" group inline-flex items-center space-x-2 text-base leading-6 font-medium hover: focus:outline-none focus: transition ease-in-out duration-150"
                >
                  <span>Discover</span>
                  {/*<!-- Item active: "", Item inactive: "text-gray-700" -->*/}
                  <svg
                    className="text-gray-700 h-5 w-5 group-hover: group-focus: transition ease-in-out duration-150"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                <div
                  className={`${
                    nav.forFamilies
                      ? "duration-200 ease-out opacity-100 translate-y-0 visible"
                      : "duration-150 opacity-0 translate-y-3 invisible"
                  } z-20 hidden md:block absolute inset-x-0 transform shadow-lg`}
                >
                  <div className="bg-white">
                    <div className="absolute left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
                      <div className="rounded-lg shadow-lg">
                        <div className="rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <div className="z-20 relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                            <Link
                              to="/projects"
                              className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                            >
                              <ProjectIcon className="h-6 w-6 flex-shrink-0 text-navy-700" />
                              <div className="space-y-1">
                                <p className="text-base leading-6 font-medium text-gray-900">
                                  Scientific Projects
                                </p>
                              </div>
                            </Link>
                            <Link
                              to="/histologies"
                              className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                            >
                              <BrainTumorTypeIcon className="h-6 w-6 flex-shrink-0 text-navy-700" />
                              <div className="space-y-1">
                                <p className="text-base leading-6 font-medium text-gray-900">
                                  Brain Tumor Types
                                </p>
                              </div>
                            </Link>
                            <Link
                              to="/publications"
                              className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                            >
                              <PublicationIcon className="h-6 w-6 flex-shrink-0 text-navy-700" />
                              <div className="space-y-1">
                                <p className="text-base leading-6 font-medium text-gray-900">
                                  Publications
                                </p>
                              </div>
                            </Link>
                            <Link
                              to="/presentations"
                              className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                            >
                              <PresentationIcon className="h-6 w-6 flex-shrink-0 text-navy-700" />
                              <div className="space-y-1">
                                <p className="text-base leading-6 font-medium text-gray-900">
                                  Presentations
                                </p>
                              </div>
                            </Link>
                            <Link
                              to="/research-resources"
                              className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                            >
                              <DataTypesIcon className="h-6 w-6 flex-shrink-0 text-navy-700" />
                              <div className="space-y-1">
                                <p className="text-base leading-6 font-medium text-gray-900">
                                  Research Resources
                                </p>
                              </div>
                            </Link>
                            <Link
                              to="/informatics-portals"
                              className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                            >
                              <InformaticsPortalsIcon className="h-6 w-6 flex-shrink-0 text-navy-700" />
                              <div className="space-y-1">
                                <p className="text-base leading-6 font-medium text-gray-900">
                                  Informatics Portals
                                </p>
                              </div>
                            </Link>
                            <Link
                              to="/pediatric-brain-tumor-atlas"
                              className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                            >
                              <PediatricBrainTumorAtlasIcon className="h-6 w-6 flex-shrink-0 text-navy-700" />
                              <div className="space-y-1">
                                <p className="text-base leading-6 font-medium text-gray-900">
                                  Pediatric Brain Tumor Atlas
                                </p>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative">
                {/*<!-- Item active: "", Item inactive: "" -->*/}
                <button
                  onClick={() =>
                    setNav({
                      ...navInit,
                      forResearchers: !nav.forResearchers,
                    })
                  }
                  type="button"
                  className=" group inline-flex items-center space-x-2 text-base leading-6 font-medium hover: focus:outline-none focus: transition ease-in-out duration-150"
                >
                  <span>Partner</span>
                  {/*<!-- Item active: "", Item inactive: "text-gray-700" -->*/}
                  <svg
                    className="text-gray-700 h-5 w-5 group-hover: group-focus: transition ease-in-out duration-150"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                <div
                  className={`${
                    nav.forResearchers
                      ? "duration-200 ease-out opacity-100 translate-y-0 visible"
                      : "duration-150 opacity-0 translate-y-3 invisible"
                  }  z-20 hidden md:block absolute inset-x-0 transform shadow-lg`}
                >
                  <div className="bg-white">
                    <div className="absolute left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
                      <div className="rounded-lg shadow-lg">
                        <div className="rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <div className="z-20 relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                            <Link
                              to="/gift-from-a-child-initiative"
                              className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                            >
                              <GiftFromAChildInitiativeIcon className="h-6 w-6 flex-shrink-0 text-navy-700" />
                              <div className="space-y-1">
                                <p className="text-base leading-6 font-medium text-gray-900">
                                  Gift from a Child Initiative
                                </p>
                              </div>
                            </Link>
                            <Link
                              to="/research-partnerships"
                              className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                            >
                              <ResearchPartnershipsIcon className="h-6 w-6 flex-shrink-0 text-navy-700" />
                              <div className="space-y-1">
                                <p className="text-base leading-6 font-medium text-gray-900">
                                  Research Partnerships
                                </p>
                              </div>
                            </Link>
                            <Link
                              to="/partnerships"
                              className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                            >
                              <FoundationPartnershipsIcon className="h-6 w-6 flex-shrink-0 text-navy-700" />
                              <div className="space-y-1">
                                <p className="text-base leading-6 font-medium text-gray-900">
                                  Foundation and Family Partnerships
                                </p>
                              </div>
                            </Link>
                            <Link
                              to="/news"
                              className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="h-6 w-6 flex-shrink-0 text-navy-700"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={1}
                                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                                />
                              </svg>
                              <div className="space-y-1">
                                <p className="text-base leading-6 font-medium text-gray-900">
                                  Newsroom
                                </p>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>

            <div className="hidden md:flex space-x-3 items-center">
              <Slide />
              <Button
                size={2}
                variant="cta"
                as={"a"}
                target="blank"
                href="https://give2.chop.edu/give/300535/#!/donation/checkout"
              >
                Donate
              </Button>

              {supportEmail && (
                <a href={`mailto:${supportEmail}`} target="blank">
                  <span className="sr-only">Email</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                </a>
              )}
              {facebookUrl && (
                <a href={facebookUrl} target="blank">
                  <span className="sr-only">Facebook</span>
                  <svg
                    className="h-6 w-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              )}
              {instagramUrl && (
                <a href={instagramUrl} target="blank">
                  <span className="sr-only">Instagram</span>
                  <svg
                    className="h-6 w-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              )}
              {twitterUrl && (
                <a href={twitterUrl} target="blank">
                  <span className="sr-only">Twitter</span>
                  <svg
                    className="h-6 w-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                  </svg>
                </a>
              )}
              {youtubeUrl && (
                <a href={youtubeUrl} target="blank">
                  <span className="sr-only">YouTube</span>
                  <svg
                    className="h-6 w-6"
                    fill="currentColor"
                    viewBox="0 0 24 20"
                  >
                    <path d="M10,2.3C0.172,2.3,0,3.174,0,10s0.172,7.7,10,7.7s10-0.874,10-7.7S19.828,2.3,10,2.3z M13.205,10.334  l-4.49,2.096C8.322,12.612,8,12.408,8,11.974V8.026C8,7.593,8.322,7.388,8.715,7.57l4.49,2.096  C13.598,9.85,13.598,10.15,13.205,10.334z" />
                  </svg>
                </a>
              )}
            </div>
          </div>
        </div>
      </header>
      <div
        className={`${
          open
            ? "duration-200 ease-out opacity-100 scale-100 visible"
            : "opacity-0 scale-95 invisible"
        } absolute top-0 inset-x-0 z-20 p-2 transition transform origin-top-right md:hidden`}
      >
        <div className="rounded-lg shadow-lg">
          <div className="rounded-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-100">
            <div className="pt-5 pb-6 px-5 space-y-6">
              <div className="flex items-center justify-between">
                <div>
                  <Link to="/" className="flex">
                    <Image
                      className="h-8 w-auto sm:h-10"
                      src={LogoImage}
                      alt="CBTN"
                    />
                  </Link>
                </div>
                <div className="-mr-2">
                  <button
                    type="button"
                    onClick={() => setOpen(false)}
                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover: hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus: transition duration-150 ease-in-out"
                  >
                    <svg
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div>
                <nav title="About Us Navigation" className="grid row-gap-8">
                  <Link
                    to="/about-us"
                    className="-mx-3 -my-1 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-6 w-6 flex-shrink-0 text-navy-700"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <div className="space-y-1">
                      <p className="text-base leading-6 font-medium text-gray-900">
                        What is the CBTN
                      </p>
                    </div>
                  </Link>
                  <Link
                    to="/institutions"
                    className="-mx-3 -my-1  p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                  >
                    <InstitutionIcon className="h-6 w-6 flex-shrink-0 text-navy-700" />
                    <div className="space-y-1">
                      <p className="text-base leading-6 font-medium text-gray-900">
                        Member Institutions
                      </p>
                    </div>
                  </Link>
                  <Link
                    to="/research-partners"
                    className="-mx-3 -my-1  p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                  >
                    <PersonIcon className="h-6 w-6 flex-shrink-0 text-navy-700" />
                    <div className="space-y-1">
                      <p className="text-base leading-6 font-medium text-gray-900">
                        Research Collaborators
                      </p>
                    </div>
                  </Link>
                  <Link
                    to="/frequently-asked-questions"
                    className="-mx-3 -my-1  p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-6 w-6 flex-shrink-0 text-navy-700"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <div className="space-y-1">
                      <p className="text-base leading-6 font-medium text-gray-900">
                        Frequently Asked Questions
                      </p>
                    </div>
                  </Link>
                </nav>
              </div>
            </div>
            <div className="pt-5 pb-6 px-5 space-y-6">
              <Smallcaps>Discover</Smallcaps>
              <nav title="Discover Navigation" className="grid row-gap-8">
                <Link
                  to="/projects"
                  className="-mx-3 -my-1  p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                >
                  <ProjectIcon className="h-6 w-6 flex-shrink-0 text-navy-700" />
                  <div className="space-y-1">
                    <p className="text-base leading-6 font-medium text-gray-900">
                      Scientific Projects
                    </p>
                  </div>
                </Link>
                <Link
                  to="/histologies"
                  className="-mx-3 -my-1  p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                >
                  <BrainTumorTypeIcon className="h-6 w-6 flex-shrink-0 text-navy-700" />
                  <div className="space-y-1">
                    <p className="text-base leading-6 font-medium text-gray-900">
                      Brain Tumor Types
                    </p>
                  </div>
                </Link>
                <Link
                  to="/publications"
                  className="-mx-3 -my-1  p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                >
                  <PublicationIcon className="h-6 w-6 flex-shrink-0 text-navy-700" />
                  <div className="space-y-1">
                    <p className="text-base leading-6 font-medium text-gray-900">
                      Publications
                    </p>
                  </div>
                </Link>
                <Link
                  to="/presentations"
                  className="-mx-3 -my-1  p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                >
                  <PresentationIcon className="h-6 w-6 flex-shrink-0 text-navy-700" />
                  <div className="space-y-1">
                    <p className="text-base leading-6 font-medium text-gray-900">
                      Presentations
                    </p>
                  </div>
                </Link>
                <Link
                  to="/research-resources"
                  className="-mx-3 -my-1  p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                >
                  <DataTypesIcon className="h-6 w-6 flex-shrink-0 text-navy-700" />
                  <div className="space-y-1">
                    <p className="text-base leading-6 font-medium text-gray-900">
                      Research Resources
                    </p>
                  </div>
                </Link>
                <Link
                  to="/informatics-portals"
                  className="-mx-3 -my-1  p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                >
                  <InformaticsPortalsIcon className="h-6 w-6 flex-shrink-0 text-navy-700" />
                  <div className="space-y-1">
                    <p className="text-base leading-6 font-medium text-gray-900">
                      Informatics Portals
                    </p>
                  </div>
                </Link>
                <Link
                  to="/pediatric-brain-tumor-atlas"
                  className="-mx-3 -my-1  p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                >
                  <PediatricBrainTumorAtlasIcon className="h-6 w-6 flex-shrink-0 text-navy-700" />
                  <div className="space-y-1">
                    <p className="text-base leading-6 font-medium text-gray-900">
                      Pediatric Brain Tumor Atlas
                    </p>
                  </div>
                </Link>
              </nav>
            </div>
            <div className="pt-5 pb-6 px-5 space-y-6">
              <Smallcaps>Partner</Smallcaps>
              <nav title="Participants Navigation" className="grid row-gap-8">
                <Link
                  to="/gift-from-a-child-initiative"
                  className="-mx-3 -my-1  p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                >
                  <GiftFromAChildInitiativeIcon className="h-6 w-6 flex-shrink-0 text-navy-700" />
                  <div className="space-y-1">
                    <p className="text-base leading-6 font-medium text-gray-900">
                      Gift from a Child Initiative
                    </p>
                  </div>
                </Link>

                <Link
                  to="/research-partnerships"
                  className="-mx-3 -my-1  p-3 flex items-center space-x-3 rounded-md hover:bg-gray-100 transition ease-in-out duration-150"
                >
                  <ResearchPartnershipsIcon className="h-6 w-6 flex-shrink-0 text-navy-700" />
                  <div className="text-base leading-6 font-medium ">
                    Research Partnerships
                  </div>
                </Link>
                <Link
                  to="/partnerships"
                  className="-mx-3 -my-1  p-3 flex items-center space-x-3 rounded-md hover:bg-gray-100 transition ease-in-out duration-150"
                >
                  <FoundationPartnershipsIcon className="h-6 w-6 flex-shrink-0 text-navy-700" />
                  <div className="text-base leading-6 font-medium ">
                    Foundation and Family Partnerships
                  </div>
                </Link>
                <Link
                  to="/news"
                  className="-mx-3 -my-1  p-3 flex items-center space-x-3 rounded-md hover:bg-gray-100 transition ease-in-out duration-150"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="h-6 w-6 flex-shrink-0 text-navy-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1}
                      d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                    />
                  </svg>
                  <div className="text-base leading-6 font-medium ">
                    Newsroom
                  </div>
                </Link>
              </nav>

              <div className="space-y-6">
                <span className="w-full flex rounded-md shadow-sm">
                  <Button
                    as={"a"}
                    href="https://give2.chop.edu/give/300535/#!/donation/checkout"
                    className="w-full"
                  >
                    Donate
                  </Button>
                </span>
                <div className="flex justify-center items-center space-x-6">
                  {supportEmail && (
                    <a href={`mailto:${supportEmail}`} target="blank">
                      <span className="sr-only">Email</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                      </svg>
                    </a>
                  )}
                  {facebookUrl && (
                    <a href={facebookUrl} target="blank">
                      <span className="sr-only">Facebook</span>
                      <svg
                        style={{ fill: "#4267B2" }}
                        className="h-6 w-6"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </a>
                  )}
                  {instagramUrl && (
                    <a href={instagramUrl} target="blank">
                      <span className="sr-only">Instagram</span>
                      <svg
                        className="h-6 w-6"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </a>
                  )}
                  {twitterUrl && (
                    <a href={twitterUrl} target="blank">
                      <span className="sr-only">Twitter</span>
                      <svg
                        style={{ fill: "#1DA1F2" }}
                        className="h-6 w-6"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                      </svg>
                    </a>
                  )}
                  {youtubeUrl && (
                    <a href={youtubeUrl} target="blank">
                      <span className="sr-only">YouTube</span>
                      <svg
                        style={{ fill: "#c4302b" }}
                        className="h-6 w-6"
                        fill="currentColor"
                        viewBox="0 0 24 20"
                      >
                        <path d="M10,2.3C0.172,2.3,0,3.174,0,10s0.172,7.7,10,7.7s10-0.874,10-7.7S19.828,2.3,10,2.3z M13.205,10.334  l-4.49,2.096C8.322,12.612,8,12.408,8,11.974V8.026C8,7.593,8.322,7.388,8.715,7.57l4.49,2.096  C13.598,9.85,13.598,10.15,13.205,10.334z" />
                      </svg>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
