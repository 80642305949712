import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { useLocalStorage } from "./UseLocalStorage";
import { Smallcaps } from "./Prose";

const useNotificationState = useLocalStorage("sitewide-notifications");

export const NotificationContainer = () => {
  const data = useStaticQuery(graphql`
    query NotificationsQuery {
      wagtail {
        page(slug: "cbtn") {
          ... on Wagtail_HomePage {
            notifications(order: "sort_order") {
              id
              name
              message
              link
            }
          }
        }
      }
    }
  `);

  const [notifications, setNotifications] = useState([]);

  const [seenNotifications, setSeenNotifications] = useNotificationState([]);

  useEffect(() => {
    setNotifications(
      data?.wagtail?.page?.notifications?.filter(
        (n) => !seenNotifications?.includes(`${n.name}-${n.id}`)
      )
    );
  }, [seenNotifications, data]);

  return notifications?.length > 0 ? (
    <div className="fixed z-50 inset-x-0 bottom-0">
      {notifications?.map((n) => (
        <Notification
          onDismiss={() =>
            setSeenNotifications([
              `${n.name}-${n.id}`,
              ...(seenNotifications || []),
            ])
          }
          key={n.id}
          notification={n}
        />
      ))}
    </div>
  ) : null;
};

export const Notification = ({ notification, onDismiss }) => {
  return (
    <div key={notification.id} className="bg-brand-blue text-white">
      <div className="max-w-screen-xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex items-center">
            <span className="hidden lg:flex p-2 rounded-lg bg-brand-navy">
              <svg
                className="h-6 w-6 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                />
              </svg>
            </span>
            <div className="mx-4  flex flex-col">
              <Smallcaps>{notification.name}</Smallcaps>
              <p className="font-sm text-white">{notification.message}</p>
            </div>
          </div>
          {notification.link && (
            <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
              <div className="rounded-md shadow-sm">
                <a
                  href={notification.link}
                  className="flex items-center justify-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-blue-900 bg-white hover:text-blue-900 focus:outline-none focus:ring transition ease-in-out duration-150"
                >
                  Learn more
                </a>
              </div>
            </div>
          )}
          <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
            <button
              type="button"
              className="-mr-1 flex p-2 rounded-md hover:bg-blue-900 focus:outline-none focus:bg-blue-900 sm:-mr-2 transition ease-in-out duration-150"
              aria-label="Dismiss"
              onClick={onDismiss}
            >
              <svg
                className="h-6 w-6 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
