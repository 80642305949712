import React, { useState, Component } from "react";
import { Transition } from "@headlessui/react";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  connectSearchBox,
  InfiniteHits,
  Configure,
} from "react-instantsearch-dom";

import { InstitutionImage } from "./Images";
import { Link } from "gatsby";
import Truncate from "react-truncate";
import { Label } from "./Labels";
import { Button } from "./Button";

const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_API_KEY
);

const searchClient = {
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      });
    }

    return algoliaClient.search(requests);
  },
};

class SearchBoxUI extends Component {
  timerId = null;

  state = {
    value: this.props.currentRefinement,
  };

  onChangeDebounced = (event) => {
    const { refine, delay } = this.props;
    const value = event.currentTarget.value;

    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => refine(value), delay);

    this.setState(() => ({
      value,
    }));
  };

  render() {
    const { value } = this.state;

    return (
      <input
        type="text"
        id="search"
        autoFocus={true}
        className="shadow-sm focus:ring-navy-700 focus:border-navy-700 block w-full sm:text-sm border-gray-500 rounded-md mb-3"
        placeholder="Search for projects, publications and more"
        value={value}
        onChange={this.onChangeDebounced}
      />
    );
  }
}
const SearchBox = connectSearchBox(SearchBoxUI);

const Hit = ({ hit }) => {
  const slug = ((typename, slug) => {
    const mapping = {
      "Research Partner": "research-partners",
      Project: "projects",
      Institution: "institutions",
      Presentation: "presentations",
      Publication: "publications",
      Partnership: "partnerships",
      "Research Partnership": "research-partnerships",
      "Tumor Type": "histologies",
      News: "news",
      Page: "",
    };

    return ["", mapping[typename] || "", slug].join("/");
  })(hit.typename, hit.slug);

  return (
    <div className="my-2">
      <Link to={slug} title={hit.title}>
        <div className="bg-white rounded-lg p-4 hover:underline hover:bg-gray-100 hover:border-gray-700">
          <div className="flex items-center">
            <div className="flex-shrink-0 h-20 w-20 rounded-lg overflow-hidden">
              <InstitutionImage image={hit?.image} height={160} />
            </div>
            <div className="ml-3 w-full">
              <div className="flex ">
                <p
                  className="text-md leading-5 font-lg text-gray-900"
                  dangerouslySetInnerHTML={{
                    __html: hit?._highlightResult?.title?.value,
                  }}
                >
                  {}
                </p>
              </div>
              <div className="flex flex-col text-sm leading-5 text-gray-700">
                <Truncate lines={3}>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: hit?._snippetResult?.description?.value,
                    }}
                  />
                </Truncate>
              </div>
              <Label>{hit?.typename}</Label>
              {hit?.tags?.map((tag) => (
                <Label key={tag}>{tag}</Label>
              ))}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
export const Slide = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <Transition show={isOpen}>
        <div className="fixed inset-0 overflow-hidden z-50">
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="absolute inset-0"
              aria-hidden="true"
              onClick={() => setIsOpen(false)}
            />

            <section
              className="absolute inset-y-0 right-0 max-w-full flex"
              aria-labelledby="slide-over-heading"
            >
              <Transition.Child
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
                className="w-screen max-w-2xl pl-10 sm:pl-16"
              >
                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <div className="py-6 px-4 bg-navy-700 sm:px-6">
                    <div className="flex items-center justify-between">
                      <h2
                        id="slide-over-heading"
                        className="text-lg font-medium text-white"
                      >
                        Site Search
                      </h2>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          onClick={() => setIsOpen(false)}
                          className="bg-navy-700 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-white"
                        >
                          <span className="sr-only">Close panel</span>
                          <svg
                            className="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="mt-1">
                      <p className="text-sm text-white">
                        Search across the The Children&apos;s Brain Tumor
                        Network
                      </p>
                    </div>
                  </div>
                  <div className="relative flex-1 py-6 px-4 sm:px-6">
                    <div className="absolute inset-0 py-6 px-4 sm:px-6">
                      <div>
                        <label htmlFor="search" className="sr-only">
                          Search
                        </label>
                        <InstantSearch
                          searchClient={searchClient}
                          indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
                        >
                          <Configure attributesToSnippet={["description:30"]} />
                          <SearchBox delay={500} />
                          <InfiniteHits hitComponent={Hit} />
                        </InstantSearch>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </section>
          </div>
        </div>
      </Transition>
      <Button radius="circle" size={"icon"} onClick={() => setIsOpen(true)}>
        <svg
          className="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </Button>
    </div>
  );
};
