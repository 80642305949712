import * as React from "react";

export const Prose = ({ children, proseOverride }) => (
  <div className={proseOverride || "prose lg:prose-lg relative w-full"}>
    {children}
  </div>
);

export const Title = ({ children }) => (
  <h1 className="mt-2 text-3xl leading-9 sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none">
    {children}
  </h1>
);

export const Smallcaps = ({ children }) => (
  <p className="font-semibold uppercase tracking-wider whitespace-nowrap">
    {children}
  </p>
);
