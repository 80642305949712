import { useState } from "react";
import createPersistedState from "use-persisted-state";

let isLocalStorageAvailable;

export const checkLocalStorage = () => {
  if (typeof isLocalStorageAvailable !== "undefined") {
    return isLocalStorageAvailable;
  }
  try {
    localStorage.setItem("TEST", "1");
    localStorage.removeItem("TEST");
    return true;
  } catch (err) {
    return false;
  }
};

export const useLocalStorage = (s) =>
  checkLocalStorage() ? createPersistedState(s) : useState;
