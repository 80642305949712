import * as React from "react";

import { tintColor, bgColor, colorList, experimentStatus } from "./Colors";

export const Label = ({ color = "gray", children }) =>
  children ? (
    <p
      className={`inline-flex items-center mr-3 mt-3 px-2 py-0.5 text-sm font-medium leading-5 text-gray-900 ${tintColor(
        color
      )}`}
    >
      {children}
    </p>
  ) : null;

export const DOILabel = ({ children }) =>
  children ? <Label color="blue">{`DOI: ${children}`}</Label> : null;

export const ExperimentStatusLabel = ({ status }) => (
  <Label color={experimentStatus(status)}>{status}</Label>
);

export const ExperimentTypeLabel = ({ children }) =>
  children ? (
    <Label color="purple">Specimen</Label>
  ) : (
    <Label color="blue">Data</Label>
  );

export const SnippetLabel = ({ snippet }) =>
  snippet?.name ? (
    <Label color={colorList[(snippet?.name || "").length % colorList.length]}>
      {snippet?.name}
    </Label>
  ) : null;

export const Dots = ({ snippet }) =>
  snippet?.name ? (
    <div
      className={`h-4 w-4 rounded-sm ${bgColor(
        colorList[(snippet?.name || "").length % colorList.length]
      )}`}
    />
  ) : null;

export const HistologyLabel = ({ histology }) =>
  histology?.label ? (
    <Label
      color={colorList[(histology?.title || "").length % colorList.length]}
    >
      {histology?.label}
    </Label>
  ) : null;
