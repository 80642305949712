export const colorList = ["green", "purple", "blue", "yellow", "orange", "red"];

export const experimentStatus = (status) => {
  const mapping: Record<string, string> = {
    Completed: "green",
    Ongoing: "orange",
    Planning: "yellow",
  };

  return mapping[status] || "gray";
};

export const bgColor = (color) => {
  const mapping: Record<string, string> = {
    green: "bg-green-100 border-green-900 border",
    purple: "bg-purple-100 border-purple-900 border",
    blue: "bg-blue-100 border-blue-900 border",
    yellow: "bg-yellow-100 border-yellow-900 border",
    orange: "bg-orange-100 border-orange-900 border",
    red: "bg-red-100 border-red-900 border",
  };

  return mapping[color] || "text-gray-900 bg-gray-100";
};

export const bgHoverColor = (color = "gray") => {
  const mapping: Record<string, string> = {
    green: "cursor-pointer hover:bg-green-100 hover:text-green-900",
    purple: "cursor-pointer hover:bg-purple-100 hover:text-purple-900",
    blue: "cursor-pointer hover:bg-blue-100 hover:text-blue-900",
    yellow: "cursor-pointer hover:bg-yellow-100 hover:text-yellow-900",
    orange: "cursor-pointer hover:bg-orange-100 hover:text-orange-900",
    red: "cursor-pointer hover:bg-red-100 hover:text-red-900",
  };

  return (
    mapping[color] || "cursor-pointer hover:bg-gray-100 hover:text-gray-900"
  );
};

export const tintColor = (color = "gray") => {
  const mapping: Record<string, string> = {
    green: "bg-green-100",
    purple: "bg-purple-100",
    blue: "bg-blue-100",
    yellow: "bg-yellow-100",
    orange: "bg-orange-100",
    red: "bg-red-100",
  };

  return mapping[color] || "bg-gray-100";
};

export const tintHoverColor = (color = "gray") => {
  const mapping: Record<string, string> = {
    green: "cursor-pointer hover:bg-green-100",
    purple: "cursor-pointer hover:bg-purple-100",
    blue: "cursor-pointer hover:bg-blue-100",
    yellow: "cursor-pointer hover:bg-yellow-100",
    orange: "cursor-pointer hover:bg-orange-100",
    red: "cursor-pointer hover:bg-red-100",
  };

  return mapping[color] || "cursor-pointer hover:bg-gray-100";
};
